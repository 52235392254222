<template>
  <div>
    <v-layout wrap justify-end>
      <v-flex lg10 xl10>
        <v-card color="#F7F7F7" tile flat>
          <v-layout wrap justify-center pa-4>
            <v-flex xs12 pt-6>
              <span style="font-family: poppinsbold; font-size: 20px">
               Add Holidays
              </span>
            </v-flex>
            <!-- <v-flex xs12 pt-4>
              <span style="color: #9e9e9e; font-family: poppinslight">
                Current Date
              </span>
            </v-flex>
            <v-flex xs12 md12 align-self-center>
              <span style="font-family: poppinsregular">
                {{ formatDate(date) }}
              </span>
            </v-flex> -->
            <v-flex xs12 md12 pt-4>
              <span style="font-family: poppinsbold"> Start Date </span>
            </v-flex>
            <v-flex xs12 md12 align-self-center>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="startdate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="startdate"
                    label="Start Date"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="startdate" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(startdate)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12 md12 pt-4>
              <span style="font-family: poppinsbold"> End Date </span>
            </v-flex>
            <v-flex xs12 md12 align-self-center>
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                :return-value.sync="enddate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="enddate"
                    label="End Date"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="enddate" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu1 = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu1.save(enddate)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12 md12 pt-4>
              <span style="font-family: poppinsbold"> Reason </span>
            </v-flex>
            <v-flex xs12 md12 align-self-center>
              <v-text-field
                outlined
                v-model="name"
                label="Name"
                dense
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md12>
              <v-btn color="#68D389" @click="add()">
                <span style="font-family: poppinsregular; color: white">
                  SUBMIT</span
                ></v-btn
              >
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["date"],
  data() {
    return {
      name: null,
      startdate: new Date().toISOString().substr(0, 10),
      enddate: new Date().toISOString().substr(0, 10),
      menu: false,
      menu1: false,
    };
  },
  watch: {
    selectedSlot: {
      handler(val) {
        if (val.seats < this.totalGuest) {
          for (var i = 0; i < this.guest.length; i++) {
            this.guest[i].value = 0;
          }
        }
      },
      deep: true,
    },
    guest: {
      handler(val) {
        this.totalGuest = val.reduce(function (prev, cur) {
          return prev + cur.value;
        }, 0);
      },
      deep: true,
    },
  },
  methods: {
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var  month =dt.getMonth()
      dt = dt.toString();
      var strTime = year+'-'+month+'-'+day;
      return strTime;
    },
    add() {
      axios({
        method: "POST",
        url: "/holiday/add",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          start: this.startdate,
          end:this.enddate,
          name: this.name,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.$emit("stepper", {
              type: "holiday",
              loadPage: true,
              response: response,
              name: this.name,
            });
          }
          this.startdate=this.formatDate(new Date())
          this.enddate=this.formatDate(new Date())
          this.name = null;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style >
input {
  color: black !important;
}
</style>