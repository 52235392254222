var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',{attrs:{"outlined":"","tile":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","text-center":"","align-self-center":"","pa-2":"","text-uppercase":""}},[_c('v-btn',{attrs:{"text":"","small":"","ripple":false,"color":"grey darken-2"},on:{"click":function($event){return _vm.$refs.calendar.prev()}}},[_c('v-icon',[_vm._v(" mdi-chevron-left ")])],1),_c('span',{staticStyle:{"font-family":"poppinsregular","font-size":"16px","color":"#000000"}},[_vm._v(" "+_vm._s(_vm.month)+" "+_vm._s(_vm.year)+" ")]),_c('v-btn',{attrs:{"text":"","small":"","ripple":false,"color":"grey darken-2"},on:{"click":function($event){return _vm.$refs.calendar.next()}}},[_c('v-icon',[_vm._v(" mdi-chevron-right ")])],1)],1)],1)],1)],1)],1),_c('v-sheet',{attrs:{"height":"600"}},[_c('v-calendar',{ref:"calendar",attrs:{"color":"primary","type":"month","events":_vm.events,"event-color":"red"},scopedSlots:_vm._u([{key:"day-label",fn:function({ day, date }){return [_c('v-layout',{style:({
            'background-color': _vm.bookingDate == date ? '#68D389' : '',
          }),attrs:{"wrap":"","justify-center":"","py-4":""}},[_c('v-flex',{style:({
              color:
                _vm.bookingDate == date
                  ? '#FFFFFF'
                  : _vm.today > date
                  ? '#f7f7f7'
                  : '#000000',
            }),attrs:{"xs12":"","pa-2":""},on:{"click":function($event){_vm.today <= date ? (_vm.bookingDate = date) : null}}},[_vm._v(" "+_vm._s(day)+" ")]),_c('v-flex',{attrs:{"xs12":""}})],1)]}},{key:"event",fn:function({ event }){return [_c('v-menu',{attrs:{"close-on-content-click":false,"activator":_vm.selectedElement,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-card',_vm._g(_vm._b({attrs:{"tile":"","color":"red","dark":""}},'v-card',attrs,false),on),[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","text-center":""}},[_vm._v(" "+_vm._s(event.name)+" ")])],1)],1)]}}],null,true),model:{value:(event.menu),callback:function ($$v) {_vm.$set(event, "menu", $$v)},expression:"event.menu"}},[_c('v-card',{attrs:{"color":"grey lighten-4","min-width":"200px","flat":""}},[_c('v-toolbar',{attrs:{"color":"red","dark":""}},[_c('v-toolbar-title',{domProps:{"innerHTML":_vm._s(event.name)}}),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteEvent(event._id)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-delete")])],1),_c('div',{staticClass:"flex-grow-1"})],1)],1)],1)]}}]),model:{value:(_vm.bookingDate),callback:function ($$v) {_vm.bookingDate=$$v},expression:"bookingDate"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }