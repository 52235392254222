<template>
  <div>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-card outlined tile>
          <v-layout wrap justify-center>
            <v-flex xs12 text-center align-self-center pa-2 text-uppercase>
              <v-btn
                text
                small
                :ripple="false"
                color="grey darken-2"
                @click="$refs.calendar.prev()"
              >
                <v-icon> mdi-chevron-left </v-icon>
              </v-btn>
              <span
                style="
                  font-family: poppinsregular;
                  font-size: 16px;
                  color: #000000;
                "
              >
                {{ month }} {{ year }}
              </span>
              <v-btn
                text
                small
                :ripple="false"
                color="grey darken-2"
                @click="$refs.calendar.next()"
              >
                <v-icon> mdi-chevron-right </v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <v-sheet height="600">
      <v-calendar
        ref="calendar"
        v-model="bookingDate"
        color="primary"
        type="month"
        :events="events"
        event-color="red"
      >
        <template v-slot:day-label="{ day, date }">
          <v-layout
            wrap
            justify-center
            :style="{
              'background-color': bookingDate == date ? '#68D389' : '',
            }"
            py-4
          >
            <v-flex
              xs12
              pa-2
              @click="today <= date ? (bookingDate = date) : null"
              :style="{
                color:
                  bookingDate == date
                    ? '#FFFFFF'
                    : today > date
                    ? '#f7f7f7'
                    : '#000000',
              }"
            >
              {{ day }}
            </v-flex>
            <v-flex xs12> </v-flex>
          </v-layout>
        </template>
        <template v-slot:event="{ event }">
          <v-menu
            v-model="event.menu"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
          >
            <template v-slot:activator="{ on, attrs }">
              <!-- <v-btn
          color="primary"
          dark
         
        > -->
              <v-card v-bind="attrs" v-on="on" tile color="red" dark>
                <v-layout wrap justify-center>
                  <v-flex xs12 text-center>
                    {{ event.name }}
                  </v-flex>
                </v-layout>
              </v-card>
              <!-- </v-btn> -->
            </template>
            <v-card color="grey lighten-4" min-width="200px" flat>
              <v-toolbar color="red" dark>
                <v-toolbar-title v-html="event.name"></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn @click="deleteEvent(event._id)" icon>
                  <v-icon dark>mdi-delete</v-icon>
                </v-btn>
                <div class="flex-grow-1"></div>
              </v-toolbar>
              <!-- <v-card-text>
                <form v-if="currentlyEditing !== event._id">
                  <v-flex>
                    {{ event.name }}
                  </v-flex>
                  <v-flex>
                    {{ event.start }}
                  </v-flex>
                  <v-flex>
                    {{ event.end }}
                  </v-flex>
                </form>
                <form v-else>
                  <v-flex xs12 md12 pt-4>
                    <span style="font-family: poppinsbold"> Start Date </span>
                  </v-flex>
                  <v-flex xs12 md12 align-self-center>
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :return-value.sync="event.start"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="event.start"
                          label="Start Date"
                          prepend-icon="mdi-calendar"
                          readonly
                          outlined
                          dense
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="event.start" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu.save(event.start)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs12 md12 pt-4>
                    <span style="font-family: poppinsbold"> End Date </span>
                  </v-flex>
                  <v-flex xs12 md12 align-self-center>
                    <v-menu
                      ref="menu1"
                      v-model="menu1"
                      :close-on-content-click="false"
                      :return-value.sync="event.end"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="event.end"
                          label="End Date"
                          prepend-icon="mdi-calendar"
                          readonly
                          outlined
                          dense
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="event.end" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu1 = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu1.save(event.end)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs12 md12 pt-4>
                    <span style="font-family: poppinsbold"> Reason </span>
                  </v-flex>
                  <v-flex xs12 md12 align-self-center>
                    <v-text-field
                      outlined
                      v-model="event.name"
                      label="Name"
                      dense
                    ></v-text-field>
                  </v-flex>
                </form>
              </v-card-text> -->
              <!-- <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="secondary" @click="event.menu = false">
                  close
                </v-btn> -->
              <!-- <v-btn
                  v-if="currentlyEditing !== event._id"
                  text
                  @click="editEvent(event)"
                >
                  edit
                </v-btn>
                <v-btn
                  text
                  v-else
                  type="submit"
                  @click="updateEvent(event)"
                >
                  Save
                </v-btn> -->
              <!-- </v-card-actions> -->
            </v-card>
          </v-menu>
        </template>
      </v-calendar>
    </v-sheet>
  </div>
</template>
<script>
import axios from "axios";
export default {
  // props: ["name"],
  data: () => ({
    today: new Date(),
    thisMonth: new Date(),
    thisYear: new Date(),
    bookingDate: new Date(),
    events: [],
    startdate: new Date().toISOString().substr(0, 10),
    enddate: new Date().toISOString().substr(0, 10),
    menu: false,
    menu1: false,
    names: [],
    namesarray: [],
    currentlyEditing: null,
    value: "",
    get: [],
    name: null,
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    months: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    month: null,
    year: null,
  }),
  beforeMount() {
    this.today = this.formatDate(this.today);
    this.thisMonth = Number(this.thisMonth.getMonth()) + 1;
    this.thisYear = this.thisYear.getFullYear();
    this.bookingDate = this.formatDate(this.bookingDate);
  },
  mounted() {
    this.getData();
  },
  watch: {
    bookingDate() {
      this.$emit("stepper", {
        date: this.bookingDate,
      });
      this.formatDate(this.bookingDate);
    },
  },
  methods: {
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var month = dt.getMonth();
      this.month = this.months[month];
      this.year = dt.getFullYear();
      month = month + 1;
      if (month < 10) month = "0" + month;
      if (day < 10) day = "0" + day;
      var strTime = this.year + "-" + month + "-" + day;
      return strTime;
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/holiday/getlist",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          start: new Date(),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.events = response.data.data;
          this.selectedEvent = this.events;
          for (var i = 0; i < this.events.length; i++) {
            this.events[i].start = new Date(this.events[i].start);
            this.events[i].end = new Date(this.events[i].end);
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    deleteEvent(ev) {
      this.appLoading = true;
      var data = {};
      data["id"] = ev;
      axios({
        url: "/holiday/remove",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.selectedOpen = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editEvent(event) {
      this.currentlyEditing = event._id;
    },
    updateEvent(ev) {
      this.appLoading = true;
      var data = {};
      data["id"] = ev;
      data["name"] = this.event.name;
      data["start"] = this.event.start;
      data["end"] = this.event.end;
      axios({
        url: "/holiday/edit",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.event.menu = false;
          if (response.data.status) {
            this.msg = "Edited Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>